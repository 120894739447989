import React from 'react'
import USBButton from '@usb-shield/react-button'
import { USBColumn } from '@usb-shield/react-grid'
import USBLink from '@usb-shield/react-link'
import { defaultColLayoutOptions } from '@/utils/usb-grid.util'
import styles from '@/components/ProductsArrayWrapper/productsArrayWrapper.module.scss'
import { TextBlockWithImageData } from '../../BlockArrayWrapper/TextBlockWithImage/textBlockWithImage.model'


const contentBlock: Object = {
  ...defaultColLayoutOptions,
  spans: {
    xlarge: 6,
    large: 6,
    medium: 8,
    small: 4,
  },
  justify: 'stretch',
  padding: 'zero',
}

const MarketingCard = ({ tiles }: any) => {
  return tiles?.map(function (value: TextBlockWithImageData, index: number) {
    let usbButton = (
        <USBButton
          variant="primary"
          size="default"
          dataTestId={`marketing-card-${index}`}
          handleClick={() => {
            window.open(value?.ctaLink, '_self')
          }}
          addClasses={styles.ctaButton}
        >
          {value?.ctaText}
        </USBButton>
      ),
      usbLink = (
        <div className={styles.linkLayout}>
          <USBLink
            linkType="basic"
            href={value?.ctaLink}
            addClasses={styles.ctaLink + ' ' + styles.solutionAreaLink}
          >
            {value?.ctaText}
          </USBLink>
        </div>
      ),
      link: any = ''
      const indexValue = value?.title.toLowerCase().replace(/[-_\s.]+(.)?/g, (_: any, c: string) => c ? c.toUpperCase() : '')

    if (value?.ctaLink?.length && value?.ctaLink?.length > 0) {
      link = value?.linkAsButton == true ? usbButton : usbLink
    }

    return (
       <React.Fragment key={indexValue}>
        <USBColumn
          layoutOpts={contentBlock}
          addClasses={
            styles.imageDisplayImageLeftLayout + ' ' + styles.responsiveImageCol
          }
        >
          <div className={styles.imageWrapper}>
            <img src={process.env.CONTENT_DOMAIN + value?.image} alt="" />
          </div>
        </USBColumn>
        <USBColumn
          layoutOpts={contentBlock}
          addClasses={
            styles.textDisplayImageLeftLayout + ' ' + styles.cardTextBody
          }
        >
          <div className={styles.block + ' ' + styles.marketBlockLayout}>
            {value.levelDownHeads ? (
              <h4 className={styles.headH4}>{value?.title}</h4>
            ) : (
              <h3 className={styles.headH3}>{value?.title}</h3>
            )}
            <span
              className={styles.text}
              dangerouslySetInnerHTML={{ __html: value?.shortDescription }}
            />
            {link}
          </div>
        </USBColumn>
      </React.Fragment>
    )
  })
}

export default MarketingCard
